import img from "../../../assets/chart.png"
import Title from '../../common/Title'
const Indicateur = () => {
    return (
        <div className="mt-[60px]">
            <Title text="Les indicateurs clés" img_src=""/>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-3 2xl:gap-7.5 mb-6">
                <div className="border border-stroke shadow-2xl rounded-lg p-6 text-white bg-gradient-to-r border-zinc-100 from-zinc-200 to-zinc-300">
                    <div className="flex justify-between text-slate-500">
                        <p className="mb-3 text-sm">Revenu</p>
                        <p className="mb-3 text-sm text-green-500">+2.34%</p>
                    </div>
                    <h1 className="text-2xl text-center text-black">XAF 405,567</h1>
                </div>
                <div className="border border-stroke shadow-2xl rounded-lg p-6 text-white bg-gradient-to-r border-zinc-100 from-zinc-200 to-zinc-300">
                    <div className="flex justify-between text-slate-500">
                        <p className="mb-3 text-sm">Revenu</p>
                        <p className="mb-3 text-sm text-green-500">+2.34%</p>
                    </div>
                    <h1 className="text-2xl text-center text-black">XAF 405,567</h1>
                </div>
                <div className="border border-stroke shadow-2xl rounded-lg p-6 text-white bg-gradient-to-r border-zinc-100 from-zinc-200 to-zinc-300">
                    <div className="flex justify-between text-slate-500">
                        <p className="mb-3 text-sm">Revenu</p>
                        <p className="mb-3 text-sm text-green-500">+2.34%</p>
                    </div>
                    <h1 className="text-2xl text-center text-black">XAF 405,567</h1>
                </div>
                
            </div>
        </div>
    )
}

export default Indicateur