import React from 'react'
interface TitleProps {
  text: string,
  img_src: string,
}
const Title: React.FC<TitleProps> = ({ text, img_src }) => {
  return (
    <>
      <h3 className={"font-bold text-xl mt-6 flex justify-start items-center " + (img_src == ""?"mb-3":"mt-3")}>
        <img src={img_src} alt="" style={{ maxHeight:"700px", maxWidth:"120px" }} className='hidden lg:block' />
        <span className='ml-3'>
          {text}
        </span>
      </h3>
      <hr className="mb-5"/>
    </>
  )
}

export default Title