import { useCallback, useEffect, useState } from "react";
import Title from "../../common/Title";
import { CardColors } from "../../../constants/card_colors";
import groupCDV from "../../../assets/groupCDV.jpg";
import app3 from "../../../assets/app3.jpeg";
import mopozi from "../../../assets/logo_mopozzi.png";
import axios from "axios";

interface ShortcutsItemType {
    id: number,
    appName: string,
    description: string,
    image: string,
    link: string
}

const items: ShortcutsItemType[] = []
const colors = CardColors;
const MyApps = () => {
    const [applications, setApplications] = useState<ShortcutsItemType[] | null>(items)
    // const updateCurrentItem = useCallback(
    //     (item: ShortcutsItemType) => {
    //         setcurrent(item)
    //     },
    //     [setcurrent],
    // );
    function randomColors() {
        return colors[Math.floor(Math.random() * colors.length)]
    }
    useEffect(() => {
        axios({
            url: process.env.REACT_APP_API_BASE_URL + '/api/dashboard?type=application',
            method: "get",
            headers:{
                "Access-Control-Allow-Origin":"*",
            }
        }).then((response) => {
              console.log(response);
              setApplications(response.data.data);
           })
           .catch((err) => {
              console.log(err.message);
           });
     }, []);
    return (
        <div className="">
            <Title text="Groupe CDV" img_src={groupCDV} />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-6">
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-slate-100 from-slate-500 rounded-lg to-slate-600 p-6 text-white">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={app3}
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">RH & ADMINISTRATION</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-purple-100 from-purple-500 rounded-lg to-purple-600 p-6 text-white">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={mopozi}
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">MARKETING</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-light-100 from-light-500 rounded-lg to-light-600 p-6 text-black">
                    <a href="http://passenger.noblesse-voyage.com/" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={mopozi}
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">PILOTAGE</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-red-100 from-red-300 rounded-lg to-red-600 p-6 text-white">
                    <a href="http://passenger.noblesse-voyage.com/" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={mopozi}
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">FINANCES</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                
            </div>
            <Title text="Noblesse Voyage" img_src="https://caisse.noblesse-voyage.com/assets/images/logo_noblesse_voyage.jpeg" />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-6">
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-yellow-100 from-yellow-500 rounded-lg to-yellow-600 p-6 text-white">
                    <a href="http://prod.noblesse-voyage.com/login" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src="http://prod.noblesse-voyage.com/assets/images/logo_clementino.png"
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">LOGISTIQUE</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-green-100 from-green-500 rounded-lg to-green-600 p-6 text-white">
                    <a href="http://caisse.noblesse-voyage.com/login" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src="http://caisse.noblesse-voyage.com/assets/images/logo_yangzi_pharma.jpeg"
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">CAISSE</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-light-100 from-light-500 rounded-lg to-light-600 p-6 text-black">
                    <a href="http://passenger-front.noblesse-voyage.com" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src="http://passenger-touristique-express.ktwasvr01.co/build/images/logo.c8c8ee48.png"
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">TICKET BUS</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-cyan-100 from-cyan-500 rounded-lg to-cyan-600 p-6 text-black">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src={mopozi}
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">VTC TAXI</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                
            </div>
            <Title text="Intercourse" img_src="http://clementino.intercoursecm.com/assets/images/logo_intercourse.JPG" />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 xl:grid-cols-4 2xl:gap-7.5 mb-6">
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-amber-100 from-amber-700 rounded-lg to-amber-950 p-6 text-white">
                    <a href="http://clementino.intercoursecm.com" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src="http://prod.noblesse-voyage.com/assets/images/logo_clementino.png"
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">LIVRAISON URBAINE</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
                <div className="bg-gradient-to-r border border-stroke shadow-2xl border-green-100 from-green-500 rounded-lg to-green-600 p-6 text-white">
                    <a href="http://caisse.noblesse-voyage.com/login" target="_blank" rel="noopener noreferrer">
                        <div className="flex">
                            <img
                                className="inline-block h-9 w-9 rounded-full ring-2 ring-white"
                                src="http://caisse.noblesse-voyage.com/assets/images/logo_yangzi_pharma.jpeg"
                                alt=""
                            />
                            <h3 className="font-bold mb-3 text-2xl ml-5">CAISSE</h3>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe, alias?</p>
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default MyApps